import { getApiURL } from '../lib/api';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useState, useCallback } from 'react';
import { FileUpload } from './file-upload';

export default function Form(props) {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [messageSent, setMessageSent] = useState(false);
    const [error, setError] = useState({ status: false, title: '', description: '', errors: null });
    const [fileList, setFileList] = useState({});
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();
            let captchaAvailable = true;
            if (!executeRecaptcha) {
                captchaAvailable = false;
                console.log('Execute recaptcha not yet available');
            }

            let data = { score: -1 };

            try {
                const gReCaptchaToken = captchaAvailable ? await executeRecaptcha(props.dataLocation) : null;
                
                if (gReCaptchaToken) {
                    const scoreResponse = await fetch('/api/captcha', {
                        header: { 'Content-Type': 'application/json' },
                        method: 'post',
                        body: JSON.stringify({ token: gReCaptchaToken })
                    });
                    const scoreResult = await scoreResponse.json();
                    
                    if (scoreResult.score) {
                        data.score = scoreResult.score;
                        // console.log('score', data.score);
                        if (data.score < 0.5) {
                            setError({
                                status: true,
                                title: props.errorTitle,
                                description: props.capchaErrorDescription,
                                errors: null
                            });
                            setMessageSent(false);
                            return;
                        }
                    }
                }

                const formData = new FormData();
                

                Array.from(event.target.elements).forEach(({ name, type, value, files, ...element }) => {
                    if (!['submit', 'file'].includes(type)) {
                        data[name] = value;
                    } else if (type === 'file') {
                        Array.from(files).forEach((file) => {
                            formData.append(`files.${name}`, file, file.name);
                        });
                    }
                });

                Object.keys(fileList).forEach((key) => {
                    Array.from(fileList[key]).forEach((f) => {
                        formData.append(`files.${key}`, f, f.name);
                    });    
                });
                    

                formData.append('data', JSON.stringify(data));
             
                // API endpoint where we send form data.
                const endpoint = getApiURL(`/api/${props.dataLocation.toLowerCase()}?populate=*`);
                const response = await fetch(endpoint, {
                    method: 'post',
                    body: formData
                });
     
                const result = await response.json();

                if (response.status !== 200) {
                    //console.log('5');
                    const err = {
                        status: true,
                        title: props.errorTitle,
                        description: props.incompleteDataErrorDescription,
                        errors: result.error.details.errors.map((e) => e.path[0])
                    };
                    setError(err);
                    setMessageSent(false);
                } else {

                    // should check the notifications
                    if (props.notificationTemplate) {
                        const notificationResponse = await fetch('/api/notification', {
                            header: { 'Content-Type': 'application/json' },
                            method: 'post',
                            body: JSON.stringify({
                                notificationTemplate: props.notificationTemplate,
                                notificationEmail: props.notificationEmail,
                                target: props.dataLocation,
                                data: result.data.attributes
                            })
                        });
                        await notificationResponse.json();
                    }
                    setError({ status: false });
                    setMessageSent(true);
                }
            } catch (e) {
                console.log(e);
            }
        },
        [props, executeRecaptcha, fileList]
    );

    return (
        <>

            <div className={`md:w-2/3 xl:w-2/3  md:mx-auto ${props.formCssClasses ? props.formCssClasses : "bg-white rounded-[0.25rem] border-[1px] border-maturity-100 shadow-lg mx-4 p-10 md:p-20 md:px-30"}`}>
                {error.status && (
                    <div key={'err'} className="w-full text-center px-20 pb-4">
                        <h2 className="text-action">{error.title}</h2>
                        <p className={`${props.descriptionCssClasses ? props.descriptionCssClasses : 'py-4'}`}>{error.description}</p>
                    </div>
                )}

                {messageSent && (
                    <div className="w-full text-center px-20 pb-4">
                        {props.confirmationTitle && (
                            <h2
                                id={`success-title-${props.dataLocation.toLowerCase()}`}
                                className="text-trust text-2xl tag-success"
                            >
                                {props.confirmationTitle}
                            </h2>
                        )}
                        {props.confirmationDescription && (
                            <p className="py-4">{props.confirmationDescription}</p>
                        )}

                        {props.confirmationButton && (
                            <button
                                type="button"
                                id="resubmit-form"
                                onClick={() => setMessageSent(false)}
                                className={`btn btn-md ${props.confirmationButton.style}`}
                            >
                                {props.confirmationButton.label}
                            </button>
                        )}
                    </div>
                )}
                {!messageSent && (
                    <>
                        {!error.status && (
                            <div className="w-full text-center px-0 md:px-20 pb-4">
                                {props.title && <h2 className={`${props.titleCssClasses ? props.titleCssClasses : 'text-trust text-2xl'}`}>{props.title}</h2>}
                                {props.description && <p>{props.description}</p>}
                            </div>
                        )}
                        <form
                            // encType="multipart/form-data"
                            onSubmit={handleSubmit}
                            action=""
                            className="space-y-4 md:space-y-8 items-left">
                            {props.fields.map((f) => {
                                if (
                                    f.fieldType === 'input.text' ||
                                    f.fieldType === 'input.email' ||
                                    f.fieldType === 'input.date' ||
                                    f.fieldType === 'input.time' ||
                                    f.fieldType === 'input.number'
                                ) {
                                    return (
                                        <div key={f.id} className="w-full md:w-1/2 inline-block p-3 text-left">
                                            <label className='text-[8px] uppercase justify-start' htmlFor={f.recordField}>{f.label}{f.required ? '*' : ''}</label>
                                            <input
                                                type={f.fieldType.replace('input.', '')}
                                                id={f.recordField}
                                                name={f.recordField}
                                                className={`shadow-sm border rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full p-3 ${error.errors?.includes(f.recordField)
                                                    ? 'border-action bg-red-100'
                                                    : 'border-maturity-100'
                                                    }`}
                                                required={f.required}
                                                placeholder={`${f.label}${f.required ? '*' : ''}`}
                                            />
                                        </div>
                                    );
                                }

                                if (f.fieldType === 'input.hidden') {
                                    return (
                                        <input key={f.id} 
                                                type="hidden"
                                                id={f.recordField}
                                                name={f.recordField}
                                                value={f.defaultValue}
                                                required={f.required}
                                            />
                                    );
                                }

                                if (f.fieldType === 'dropdown') {
                                    return (
                                        <div key={f.id} className="ww-full md:w-1/2 inline-block p-3 text-left">
                                            <label className='text-[8px] uppercase justify-start' htmlFor={f.recordField}>{f.recordField}{f.required ? '*' : ''}</label>
                                            <select
                                                id={f.recordField}
                                                name={f.recordField}
                                                className={`shadow-sm  border  rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full p-3 ${error.errors?.includes(f.recordField)
                                                    ? 'border-action bg-red-100'
                                                    : 'border-maturity-100'
                                                    }`}
                                                required={f.required}
                                                placeholder={`${f.label}${f.required ? '*' : ''}`}
                                            >
                                                <option value="" disabled defaultValue>
                                                    {`${f.label}${f.required ? '*' : ''}`}
                                                </option>
                                                {f.options.map((opt) => {
                                                    return (
                                                        <option key={opt.id} value={opt.label}>
                                                            {opt.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    );
                                }

                                if (f.fieldType === 'file' || f.fieldType === 'files') {
                                    return (
                                        <>
                                            
                                         {/* <div key={f.id} className="w-full md:basis-1/2 p-3 text-left">
                                            <label className='text-[8px] uppercase justify-start' htmlFor={f.recordField}>{f.recordField}{f.required ? '*' : ''}</label>
                                            <input
                                                type="file"
                                                multiple={f.fieldType === 'files'}
                                                id={f.recordField}
                                                name={f.recordField}
                                                className={`file:mr-5 file:py-3 file:px-10 file:border-0 file:text-md file:font-semibold file:bg-maturity-100 file:text-default hover:file:cursor-pointer hover:file:opacity-80 shadow-sm  border rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full  ${error.errors?.includes(f.recordField)
                                                    ? 'border-action bg-red-100'
                                                    : 'border-maturity-100'
                                                    }`}
                                                required={f.required}
                                                placeholder={`${f.label}${f.required ? '*' : ''}`}
                                            />
                                            </div> */}
                                          
                                            <div key={f.id} className="w-full p-3">
                                                <FileUpload {...f} setFileList={setFileList} />
                                            </div> 
                                        </>
                                    );
                                }

                                if (f.fieldType === 'textarea') {
                                    return (
                                        <div key={f.id} className="w-full p-3 text-left">
                                            <label className='text-[8px] uppercase justify-start' htmlFor={f.recordField}>{f.recordField}{f.required ? '*' : ''}</label>
                                            <textarea
                                                id={f.recordField}
                                                name={f.recordField}
                                                rows="6"
                                                className={`shadow-sm border rounded-[0.25rem] placeholder:uppercase placeholder:text-xs placeholder:font-semibold focus:ring-primary-500 focus:border-primary-500 block w-full p-3 ${error.errors?.includes(f.recordField)
                                                    ? 'border-action bg-red-100'
                                                    : 'border-maturity-100'
                                                    }`}
                                                required={f.required}
                                                placeholder={`${f.label}${f.required ? '*' : ''}`}
                                            ></textarea>
                                        </div>
                                    );
                                }
                            })}

                            {props.submitButton ? (
                                <div className="w-full p-3 text-center">
                                    {/* <hr className='py-2' /> */}
                                    <button
                                        id="submit-form"
                                        type="submit"
                                        className={`btn btn-md ${props.submitButton.style}`}
                                    >
                                        {props.submitButton.label}
                                    </button>
                                </div>
                            ) : (
                                <button id="submit-form" type="submit" className="btn btn-md trust-full ">
                                    Send message
                                </button>
                            )}
                        </form>
                    </>
                )}
            </div>

        </>
    );
}
